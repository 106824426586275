
  
  a {
    text-decoration: none;
    display: inline-block;
    padding: 4px 15.5px 6px 15.5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // a:hover {
  //   background-color: #ddd;
  //   color: black;
  // }
  
  .previous {
      background-color: #556ee6;
      color: white;
      font-size: 18px;
  }
  
  .next {
      margin-right: 20px;
    background-color: #556ee6;
    color: white;
    font-size: 18px;
  }
  
  .round {
    border-radius: 50%;
  }
  
  .disable{
      opacity: 0.8;
  }
  
  @media (min-width:480px) {
      .mobile-view{
          display: none !important;
      }
      
  }

  @media (max-width:480px) {
    .mobile-pagination{
      position:fixed ;
      bottom: 80px;
      width: 95%;
    }
    .paginate-right-button{
      display: none;
    }
    .paginate-left-button{
      display: none;
    }
    .footerView{
      flex-direction: column;
      justify-content: start;
      align-items: flex-end !important;
    }
   
    .mobile-view{
      display: none ;
      position:fixed ;
      bottom: 120px;
      width: 95%;
      // max-width: 296px;
  }
  .producttable{
    overflow-y: scroll;
    // overflow-x: hidden !important
   
  }
}
.producttable::-webkit-scrollbar {
  display: none;
}
@media (max-width:1200px) {
    .downloadbtn{
      display: flex;
      justify-content: flex-start;
      padding:5px 0 !important;
    }
  }
  @media (max-width:600px) {
    .nextView{
      
      justify-content: flex-end;
    }
    .middleView{
      justify-content: center;
      display: flex;
    }
  }